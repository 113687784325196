import React from 'react'
import styles from './EventList.module.css'

export default function EventList ({ events, handleClick }) {
  return (
    <div>
      {events.map((event, index) => (
        <div className={styles.card} key={event.id}>
          <h2>
            {index} - {event.title.toUpperCase()}
            <p>
              {event.location.toUpperCase()} - {event.date}
            </p>
            <br />
            <button onClick={() => handleClick(event.id)}>Delete item</button>
          </h2>
        </div>
      ))}
    </div>
  )
}
