/* eslint-disable jsx-a11y/anchor-is-valid */
import './App.css'
import React, { useState } from 'react'
import Title from './components/Title'
import Modal from './components/Modal'
import EventList from './components/EventList'
import NewEventForm from './components/NewEventForm'

function App () {
  const [showModal, setShowModal] = useState(true)
  const [showEvents, setShowEvents] = useState(true)
  const [events, setEvents] = useState([])

  const addEvent = event => {
    setEvents(prevEvents => {
      return [...prevEvents, event]
    })
    setShowModal(false)
  }

  const handleClick = id => {
    setEvents(prevEvents => {
      return prevEvents.filter(event => {
        return id !== event.id
      })
    })
    console.log(id)
  }

  const subtitle = 'The lastest events in Marioland'

  return (
    <div className='App'>
      <Title title='Events in Your Area' subtitle={subtitle} />
      {showEvents && (
        <div>
          <button className='red' onClick={() => setShowEvents(false)}>
            Hide Events
          </button>
        </div>
      )}
      {!showEvents && (
        <div>
          <button className='green' onClick={() => setShowEvents(true)}>
            Show Events
          </button>
        </div>
      )}

      {showEvents && <EventList events={events} handleClick={handleClick} />}

      {showModal && (
        <Modal isSalesModal={false}>
          <NewEventForm addEvent={addEvent} />
        </Modal>
      )}
      <div>
        <button onClick={() => setShowModal(true)}>Add New Event</button>
      </div>
    </div>
  )
}

export default App
